<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="formatedData()"
      line-numbers
      :search-options="{
        enabled: true,
      }"
      :pagination-options="{
        enabled: true,
      }"
      :theme="isDarkMode()"
      @on-row-click="onRowClick"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'after'">
          <template>
            <CDropdown color="transparent p-0" placement="bottom-end">
              <template #toggler-content>
                <CButton color="facebook" class="px-4"> Action </CButton>
              </template>

              <CDropdownItem @click="showModal('Approve')"
                ><CButton color="success" class="px-4"> Approve </CButton>
              </CDropdownItem>

              <CDropdownItem @click="showModal()"
                ><CButton color="danger" class="px-4"> Reject </CButton>
              </CDropdownItem>
            </CDropdown>
          </template>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <CModal :show.sync="modalVisibility" color="dark" :closeOnBackdrop="false">
      <CRow>
        <CCol md="1"> </CCol>
        <CCol md="10">
          <CCard class="mx-4 mb-0">
            <CCardBody class="m-8">
              <h3>
                Are you sure you want to {{ subText }} {{ currentRow.sender }} ?
              </h3>

              <div class="decision-container">
                <CButton
                  block
                  color="facebook"
                  style="margin: 2rem"
                  type="submit"
                  @click="handlePatchSenderName"
                  >Yes</CButton
                >

                <CButton
                  block
                  color="facebook"
                  style="margin: 2rem"
                  type="submit"
                  @click="closeModal"
                  >No</CButton
                >
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <div style="margin-bottom: 1.5rem" slot="footer-wrapper"></div>
      <div style="margin-bottom: 1.5rem" slot="header-wrapper"></div>
    </CModal>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import { mapActions, mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {} from '@/services/notification.service';

export default {
  name: 'Pending',
  components: {
    VueGoodTable,
  },
  data() {
    return {
      columns: [
        {
          label: 'Sender Names',
          field: 'sender',
        },
        { label: 'Username', field: 'user_name' },
        {
          label: 'Message',
          field: 'message',
        },
        {
          label: 'Date Created',
          field: 'created_at',
        },
        {
          label: 'Date Updated',
          field: 'updated_at',
        },
        {
          label: 'Action',
          field: 'after',
        },
      ],
      modalVisibility: false,
      subText: '',
      currentRow: {},
    };
  },

  notifications: {
    approveSuccess: {
      title: 'Success',
      message: 'You have successfully APPROVED this sender name ',
      type: 'success',
    },
    declineSuccess: {
      title: 'Success',
      message: 'You have successfullt DECLINED this sender name ',
      type: 'success',
    },
    updateFailed: {
      title: 'Failed',
      message:
        'Update failed at this time, kindly check your network and try again!',
      type: 'error',
    },
  },
  created() {
    this.fetchSenderNames(1);
  },

  computed: {
    ...mapGetters('admin', ['senderNames']),
    ...mapState('coreuiStore', ['darkMode']),
  },

  methods: {
    ...mapActions('admin', ['fetchSenderNames', 'patchSenderName']),

    onRowClick(params) {
      if (this.modalVisibility == true) return;
      this.currentRow = params.row;
    },

    showModal(type) {
      this.modalVisibility = true;

      if (type === 'Approve') return (this.subText = 'approve');

      this.subText = 'reject';
    },

    closeModal() {
      this.currentRow = {};
      this.modalVisibility = false;
      this.subText = '';
    },

    isDarkMode() {
      return this.darkMode ? 'nocturnal' : null;
    },

    formatedData() {
      const dayJs = dayjs.extend(advancedFormat);
      return this.senderNames.map((names) => ({
        ...names,
        created_at: dayJs(names.created_at).format('Do MMM YYYY'),
        updated_at: dayJs(names.updated_at).format('Do MMM YYYY'),
      }));
    },

    handlePatchSenderName: async function () {
      const data = {
        approval_status_id: this.subText === 'approve' ? 2 : 3,
        sender_id: this.currentRow.id,
      };

      const status = await this.patchSenderName({ data: data });

      if (!status) {
        this.updateFailed({ message: this.serverResponse?.message });
        this.closeModal();
      } else {
        if (this.subText === 'approve') {
          this.approveSuccess();
          this.closeModal();
          setTimeout(window.location.reload(), 3000);
        } else {
          this.declineSuccess();
          this.closeModal();
          setTimeout(window.location.reload(), 3000);
        }
      }
    },
  },
};
</script>

<style>
.decision-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
